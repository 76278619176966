import { Bar, mixins } from 'vue-chartjs';

const { reactiveProp } = mixins;

export default {
  extends: Bar,
  mixins: [reactiveProp],
  props: {
    options: {
      required: false,
      type: Object,
      default: () => ({
        responsive: false,
        maintainAspectRatio: false,
      }),
    },
  },
  mounted() {
    // @ts-ignore
    this.renderChart(this.chartData, this.options);
  },
};
